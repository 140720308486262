import Link from '@Components/common/Link';
import { Spinner } from '@Components/common/Spinner';
import { COOKIE_LANG } from '@Constants/index';
import { useAuth } from '@Contexts/AuthContext/Auth.context';
import { configureAxiosLocale } from 'lib/Axios';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import CartIcon from 'public/icons/cart-icon.svg';
import MenuIcon from 'public/icons/main-menu.svg';
import Logo from 'public/logo.svg';
import React, { Fragment, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { components } from 'react-select';
import { useClickAway, useCookie } from 'react-use';
import { RootState } from 'store';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import MainMenu from './MainMenu';
import {
  CartCount,
  CartLink,
  ChangeLanguageSelect,
  Inner,
  LogoLink,
  MenuBtn,
  NavActions,
  NavbarWrapper,
  NavButton,
  NavLi,
  NavLink,
  NavLinks,
  NavUl,
  Overlay,
} from './Navbar.styled';
import UserMenu from './UserMenu';
import GlobeIcon from '/public/globe-black.svg';

type Props = {};

const ValueContainer = ({ children, ...props }: any) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        <Image src={GlobeIcon} width={12} height={12} alt="Globe Icon" priority />
        {children}
      </components.ValueContainer>
    )
  );
};

const languages = [
  {
    label: 'العربية',
    value: 'ar',
  },
  {
    label: 'English',
    value: 'en',
  },
];

export default React.memo(function Navbar({}: Props) {
  const { userData, isUserFetching } = useAuth();
  const { asPath, push, locale, pathname, query, route } = useRouter();
  const menuRef = useRef<HTMLElement>(null);
  const [showMainMenu, setShowMainMenu] = useState(false);
  const { t } = useTranslation('common');
  const { data: cartData } = useSelector((state: RootState) => state.cart);
  const cartCount = cartData?.items.reduce((acc, curr) => acc + curr.quantity, 0);
  const hideNav = false;

  useClickAway(menuRef, () => {
    setShowMainMenu(false);
  });

  const [, updateCookieLang] = useCookie(COOKIE_LANG);

  const switchLang = (lang: any) => {
    push(asPath, asPath, {
      locale: lang.value,
    }).then(() => {
      configureAxiosLocale(lang.value);
      updateCookieLang(lang.value);
    });
  };

  return (
    <Fragment>
      <NavbarWrapper>
        <Container>
          <Row>
            <Col col={12}>
              <Inner>
                <MenuBtn onClick={() => setShowMainMenu(true)}>
                  <Image src={MenuIcon} width={36} height={36} alt="Menu" priority />
                </MenuBtn>
                <LogoLink href="/">
                  <Image src={Logo} width={156.75} height={24.41} alt="YourParts" priority />
                </LogoLink>
                <NavLinks>
                  {!hideNav && (
                    <NavUl>
                      <NavLi>
                        <NavLink href="/products" isActive={route.includes('/products')}>
                          {t('products')}
                        </NavLink>
                      </NavLi>
                      {/* <NavLi>
                        <NavLink href="/services" isActive={route === '/services'}>
                          {t('services')}
                        </NavLink>
                      </NavLi> */}
                      {/* 
                      <NavLi>
                        <NavLink href="/services" isActive={route === '/services'}>
                          {t('services')}
                        </NavLink>
                      </NavLi> */}
                    </NavUl>
                  )}
                </NavLinks>
                <NavActions>
                  <ChangeLanguageSelect
                    components={{
                      ValueContainer,
                      IndicatorSeparator: () => <></>,
                      DropdownIndicator: () => <></>,
                    }}
                    isSearchable={false}
                    isClearable={false}
                    classNamePrefix="lang"
                    options={languages}
                    defaultValue={languages.find((lang) => lang.value === locale)}
                    onChange={switchLang}
                  />
                  <CartLink href="/cart">
                    <Image src={CartIcon} width={24} height={21.65} alt="Cart" priority />
                    {Boolean(cartCount) && <CartCount>{cartCount}</CartCount>}
                  </CartLink>
                </NavActions>
                {isUserFetching ? (
                  <Spinner />
                ) : Boolean(userData) ? (
                  <UserMenu />
                ) : (
                  <Fragment>
                    <NavButton variant="default" as={Link} href="/auth/login">
                      {t('login')}
                    </NavButton>
                    <NavButton variant="filled-red" as={Link} href="/auth/register">
                      {t('register')}
                    </NavButton>
                  </Fragment>
                )}
              </Inner>
            </Col>
          </Row>
        </Container>
      </NavbarWrapper>
      <Overlay isActive={showMainMenu} />
      <MainMenu ref={menuRef} setShowMainMenu={setShowMainMenu} showMainMenu={showMainMenu} />
    </Fragment>
  );
});
