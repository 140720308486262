import Link from '@Components/common/Link';
import styled from 'styled-components';
import { mq } from 'stylesheets/utils';

export const Wrapper = styled.footer`
  padding: 80px 0 60px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  ${mq(768)} {
    padding: 60px 0 30px;
  }
`;

export const Copy = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 30px 0 30px;
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.white};
  ${mq(768)} {
    padding: 18px 0 0 0;
    flex-direction: column;
  }
`;

export const Privacy = styled.div`
  display: flex;
  align-items: center;
  ${mq(768)} {
    margin-top: 10px;
  }
`;

export const PrivacyLink = styled(Link)`
  font-size: 1.3rem;
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.white};
  margin-right: 70px;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: 1px;
    height: 20px;
    top: 0;
    right: 0;
    transform: translateX(40px);
    background-color: ${({ theme }) => theme.colors.white};
  }
  &:last-of-type {
    margin-right: 0;
    &::after {
      display: none;
    }
  }
  ${mq(768)} {
    margin-right: 40px;
    &::after {
      transform: translateX(20px);
    }
  }
`;

export const FooterContent = styled.div`
  padding: 0 20px 64px 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
  ${mq(576)} {
    padding: 0 0 34px 0;
  }
`;

export const LogoLink = styled(Link)`
  display: inline-block;
  height: fit-content;
  margin: 12px 0 42px 0;
  height: 34px;
`;

export const SocialIconsList = styled.div`
  max-width: 165px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const SocialIcon = styled.a``;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  text-transform: capitalize;
  padding: 0 2px;
  margin-bottom: 8px;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightGray};
  max-width: 272px;
  min-width: 272px;
  width: 100%;
  height: 4.8rem;
  border-radius: 0.4rem;
  overflow: hidden;
  padding-left: 16px;
  box-shadow: 3px 3px 5px 5px rgba(0, 0, 0, 0.16);
`;

export const Input = styled.input`
  font-family: ${({ theme }) => theme.typography.fontFamily};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.black2};
  font-size: ${({ theme }) => theme.fontsizes.body14};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  line-height: 4.8rem;
  width: 100%;
  border: none;
  &::placeholder {
    color: ${({ theme }) => theme.colors.black2};
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  max-width: 48px;
  height: 48px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.brandColor};
  border: none;
  transform: none /*rtl: scale(-1, 1) */;
`;

export const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.fontsizes.body14};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  margin-bottom: 18px;
  display: block;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.fontsizes.body14};
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  opacity: 0.5;
  margin: 40px 0 26px 0;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 6px;
  ${mq(1200)} {
    align-items: flex-start;
  }
`;

export const HotLine = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 27px;
  .hotline-num {
    color: ${({ theme }) => theme.colors.white};
    position: relative;
    font-size: 3.8rem;
    margin-left: 20px;
    font-weight: ${({ theme }) => theme.fontWeight.font400};
    span {
      position: absolute;
      color: ${({ theme }) => theme.colors.white};
      opacity: 0.5;
      left: 0;
      top: 0;
      transform: translateY(-8px);
      font-size: 1.4rem;
    }
  }
  ${mq(1200)} {
    margin-top: 20px;
  }
`;

export const DownloadApp = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Overline = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body12};
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  margin-bottom: 15px;
`;

export const Store = styled.a`
  margin-bottom: 15px;
  display: block;
`;
